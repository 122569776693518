import React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Paragraph from './Paragraph';
import CompletionPieChart from './CompletionPieChart';

const WelcomeMessage = ({ name }) => {
    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <Stack direction="row" spacing={2}>
            <Stack spacing={2}>
                <Paragraph>
                    {`<b style='color: #333333;'>Welcome to Learncmd, ${name}!</b>`}
                </Paragraph>
                <Paragraph>
                    {"Get ready to master the terminal and take your command-line skills to the next level."}
                </Paragraph>
                <Paragraph>
                    {"Below, you’ll find the course modules designed to guide you step by step. Don’t forget to explore the side menu for additional resouces and tools."}
                </Paragraph>
                <Paragraph>
                    {"Complete all the modules to earn your certificate, a great way to showcase your terminal expertise and enhance your resume."}
                </Paragraph>
                <Box sx={{ height: 40 }} />
                {isMobile && (
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <CompletionPieChart completed={5} total={10} showLegend={false} />
                    </Box>
                )}
            </Stack>
            {!isMobile && <CompletionPieChart completed={5} total={10} showLegend={false} />}
        </Stack>
    );
};

const WalkthroughMessage = () => {
    return (
        <Stack spacing={2}>
            <Paragraph>
                {`Explore our Walkthroughs to dive deep into popular terminal commands.`}
            </Paragraph>
            <Box sx={{ height: 40 }} />
        </Stack>
    );
};

const ArticleMessage = () => {
    return (
        <Stack spacing={2}>
            <Paragraph>
                {`Explore our Walkthroughs to dive deep into popular terminal commands.`}
            </Paragraph>
            <Box sx={{ height: 40 }} />
        </Stack>
    );
};

export { WelcomeMessage, WalkthroughMessage };