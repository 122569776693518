import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import RouteTitle from '../components/RouteTitle';
import ConfigurableField from '../components/ConfigurableField';
import { CustomButton } from '../components/CustomButton';
import PaddedBox from '../components/PaddedBox';
import { auth, db } from '../firebaseConfig';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';

const Profile = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [firstNameHelperText, setFirstNameHelperText] = useState('');
  const [lastNameHelperText, setLastNameHelperText] = useState('');
  const [isFileAvailable, setIsFileAvailable] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [message, setMessage] = useState(''); // State for error or success message
  const [isSuccess, setIsSuccess] = useState(false); // State for success or error

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setFirstName(userData.firstName || '');
          setLastName(userData.lastName || '');
        } else {
          // Create a new document for the user if it doesn't exist
          await setDoc(userDocRef, {
            firstName: '',
            lastName: '',
            email: user.email
          });
        }
      }
    };

    const checkFileExists = async () => {
      const storage = getStorage();
      const user = auth.currentUser;
      if (user) {
        const fileName = `${user.uid}.pdf`;
        const fileRef = ref(storage, `certificates/${fileName}`);
        try {
          await getDownloadURL(fileRef);
          setIsFileAvailable(true);
        } catch (error) {
          setIsFileAvailable(false);
        }
      }
    };

    fetchUserData();
    checkFileExists();
  }, []);

  useEffect(() => {
    const isValid = firstName.length >= 2 && lastName.length >= 2;
    setIsFormValid(isValid);
  }, [firstName, lastName]);

  const handleSave = async () => {
    setMessage(''); // Clear previous message
    setIsSuccess(false); // Reset success state
    if (firstName.length < 2) {
      setFirstNameHelperText('First name must be at least 2 characters');
      return;
    }
    if (lastName.length < 2) {
      setLastNameHelperText('Last name must be at least 2 characters');
      return;
    }

    const user = auth.currentUser;
    if (user) {
      try {
        await updateDoc(doc(db, 'users', user.uid), {
          firstName,
          lastName
        });
        setMessage('Profile updated successfully');
        setIsSuccess(true);
      } catch (error) {
        console.error('Error updating profile: ', error);
        setMessage('Error updating profile');
        setIsSuccess(false);
      }
    }
  };

  const handleDownload = async () => {
    const storage = getStorage();
    const user = auth.currentUser;
    if (user) {
      const fileName = `${user.uid}.pdf`;
      const fileRef = ref(storage, `certificates/${fileName}`);
      try {
        const url = await getDownloadURL(fileRef);
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank'; // Opens the file in a new tab
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error('Error downloading file: ', error);
        alert('Error downloading file');
      }
    }
  };

  return (
    <>
      <PaddedBox title="Profile" showBanner={false}>
        <Box sx={{ width: 300 }} >
          <ConfigurableField 
            label="First name:" 
            textFieldId="first-name" 
            onChange={(e) => {
              setFirstName(e.target.value);
              setFirstNameHelperText(e.target.value.length >= 2 ? '' : 'First name must be at least 2 characters');
            }} 
            value={firstName} 
            helperText={firstNameHelperText} 
          />
          <ConfigurableField 
            label="Last name:" 
            textFieldId="last-name" 
            onChange={(e) => {
              setLastName(e.target.value);
              setLastNameHelperText(e.target.value.length >= 2 ? '' : 'Last name must be at least 2 characters');
            }} 
            value={lastName} 
            helperText={lastNameHelperText} 
          />
          <Box sx={{ height: 40 }} />
          {message && (
            <Box sx={{ color: isSuccess ? '#555555' : '#a2423d', marginBottom: 4 }}>
              {message}
            </Box>
          )}
          <CustomButton text="Save" onClick={handleSave} disabled={!isFormValid} />
          <Box sx={{ height: 60 }} />
        </Box>
        <Box>
          <RouteTitle title="Certificate"/>
          <Box sx={{ height: 30 }} />
          <Box>
            {isFileAvailable ? "Certificate of Completion is ready for download" : "Certificate of Completion can be downloaded only after all the lessons are completed."}
          </Box>
          <Box sx={{ height: 40 }} />
          <CustomButton text="Download" onClick={handleDownload} disabled={!isFileAvailable} />
        </Box>
      </PaddedBox>
    </>
  );
};

export default Profile;