import React from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import './MarkdownComponent.css';

const MarkdownComponent = ({ markdownContent }) => {
    return (
        <div className="markdown-content">
            <Markdown remarkPlugins={[remarkGfm]}>
                {markdownContent}
            </Markdown>
        </div>
    );
};

export default MarkdownComponent;