import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

const CommandSnippet = ({ children }) => {
    const lines = children.split('<br>').map((line, index) => (
        <div key={index} style={{ color: '#FFFFFF' }}>{line}</div>
    ));

    return (
        <Box sx={{ p: 2, bgcolor: '#8C8C8C', fontFamily: 'Roboto Mono, monospace', fontSize: '13px', borderRadius: '8px' }}>
            {lines}
        </Box>
    );
};

CommandSnippet.propTypes = {
    children: PropTypes.string.isRequired,
};

export default CommandSnippet;