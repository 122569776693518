import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { CustomOpenButton } from './CustomButton'; // Import the CustomNextButton component
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const Module = ({ title, content, route, disabled, completedText }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <Box
            sx={{
                backgroundColor: '#EDEDED',
                borderRadius: 2,
                paddingY: 0,
                paddingX: 2,
                paddingBottom: 2,
                position: 'relative', // Ensure the Box is positioned relatively
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                justifyContent: 'space-between',
                marginBottom: 2,
                minHeight: 120
            }}
        >
            <Box sx={{ paddingRight: isMobile ? 0 : 10 }}>
                <h2 style={{ fontSize: 18, color: '#333333' }}>{title}</h2>
                <p style={{ fontSize: 14, color: '#555555' }}>{content}</p>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: isMobile ? 'row' : 'column',
                    justifyContent: 'space-between',
                    alignItems: isMobile ? 'center' : 'flex-end',
                    marginTop: isMobile ? 2 : 0,
                    width: isMobile ? '100%' : 'auto'
                }}
            >
                <p style={{ fontSize: 14, color: disabled ? '#c4c4c4' : completedText.color, marginRight: isMobile ? 'auto' : 0 }}>{completedText.text}</p>
                <CustomOpenButton route={route} disabled={disabled} />
            </Box>
        </Box>
    );
};

Module.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    route: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    completedText: PropTypes.shape({
        text: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
    }).isRequired,
};

export default Module;