import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import RouteTitle from '../components/RouteTitle';
import ConfigurableField from '../components/ConfigurableField';
import { CustomButton } from '../components/CustomButton';
import PaddedBox from '../components/PaddedBox';
import { auth } from '../firebaseConfig';
import { updatePassword } from 'firebase/auth';

const Security = () => {
  const [newPassword, setNewPassword] = useState('');
  const [repeatNewPassword, setRepeatNewPassword] = useState('');
  const [newPasswordHelperText, setNewPasswordHelperText] = useState('');
  const [repeatNewPasswordHelperText, setRepeatNewPasswordHelperText] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  const validatePassword = (password) => {
    return password.length >= 6; // Example validation rule
  };

  const getErrorMessage = (error) => {
    switch (error.code) {
      case 'auth/weak-password':
        return 'The password is too weak.';
      case 'auth/requires-recent-login':
        return 'Please log in again and try updating your password.';
      case 'auth/user-not-found':
        return 'User not found.';
      default:
        return 'An unexpected error occurred. Please try again.';
    }
  };

  useEffect(() => {
    const isPasswordValid = validatePassword(newPassword);
    const doPasswordsMatch = newPassword === repeatNewPassword;
    setIsSaveDisabled(!(isPasswordValid && doPasswordsMatch));
  }, [newPassword, repeatNewPassword]);

  const handlePasswordUpdate = async () => {
    setErrorMessage('');
    setSuccessMessage('');

    if (newPassword !== repeatNewPassword) {
      setErrorMessage('New passwords do not match');
      return;
    }

    const user = auth.currentUser;
    if (user) {
      try {
        await updatePassword(user, newPassword);
        setSuccessMessage('Password updated successfully');
      } catch (error) {
        setErrorMessage(getErrorMessage(error));
      }
    }
  };

  return (
    <PaddedBox title="Security" showBanner={false}>
      <Box sx={{ width: 325 }} >
        <ConfigurableField 
          label="New password:" 
          textFieldId="new-password" 
          type="password" 
          onChange={(e) => {
            setNewPassword(e.target.value);
            setNewPasswordHelperText(validatePassword(e.target.value) ? '' : 'Password must be at least 6 characters long');
          }} 
          helperText={newPasswordHelperText} 
        />
        <ConfigurableField 
          label="Repeat new password:" 
          textFieldId="repeat-new-password" 
          type="password" 
          onChange={(e) => {
            setRepeatNewPassword(e.target.value);
            setRepeatNewPasswordHelperText(e.target.value === newPassword ? '' : 'Passwords do not match');
          }} 
          helperText={repeatNewPasswordHelperText} 
        />
        <Box sx={{ height: 40 }} />
        {errorMessage && <Box sx={{ color: '#a2423d', marginBottom: 2 }}>{errorMessage}</Box>}
        {successMessage && <Box sx={{ marginBottom: 2, }}>{successMessage}</Box>}
        <Box sx={{ height: 20 }} />
        <CustomButton text="Save" onClick={handlePasswordUpdate} disabled={isSaveDisabled} />
      </Box>
    </PaddedBox>
  );
};

export default Security;