import React, { useState } from 'react';
import Box from '@mui/material/Box';
import RouteTitle from '../components/RouteTitle';
import ConfigurableField from '../components/ConfigurableField';
import { CustomButtonWide } from '../components/CustomButton';
import { useHistory, useLocation, Link } from "react-router-dom";
import PaddedCentralBox from '../components/PaddedCentralBox';
import { confirmPasswordReset } from 'firebase/auth';
import { auth } from '../firebaseConfig';

const NewPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordHelperText, setPasswordHelperText] = useState('');
  const [confirmPasswordHelperText, setConfirmPasswordHelperText] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const validatePassword = (password) => {
    return password.length >= 6;
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setPasswordHelperText(newPassword.length >= 6 ? '' : 'Password must be at least 6 characters');
    setConfirmPasswordHelperText(newPassword === confirmPassword ? '' : 'Passwords do not match');
    setIsFormValid(validatePassword(newPassword) && newPassword === confirmPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);
    setConfirmPasswordHelperText(newConfirmPassword === password ? '' : 'Passwords do not match');
    setIsFormValid(validatePassword(password) && password === newConfirmPassword);
  };

  const handleNewPassword = async () => {
    setErrorMessage('');
    setSuccessMessage('');
    const query = new URLSearchParams(location.search);
    const oobCode = query.get('oobCode');

    if (!validatePassword(password) || password !== confirmPassword) {
      setErrorMessage('Passwords do not match or are too short.');
      return;
    }

    try {
      await confirmPasswordReset(auth, oobCode, password);
      setSuccessMessage('Password has been reset successfully.');
    } catch (error) {
      setErrorMessage('An error occurred while resetting the password. Please try again.');
      console.error("Error resetting password: ", error);
    }
  };

  return (
    <PaddedCentralBox>
      <Box sx={{ width: 325 }}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <RouteTitle title="Set New Password" />
        </Box>
        <Box sx={{ height: 40 }} />
        {!successMessage && (
          <>
            <ConfigurableField 
              label="New Password:" 
              textFieldId="password" 
              type="password"
              onChange={handlePasswordChange} 
              helperText={passwordHelperText}
            />
            <Box sx={{ height: 20 }} />
            <ConfigurableField 
              label="Confirm Password:" 
              textFieldId="confirmPassword" 
              type="password"
              onChange={handleConfirmPasswordChange} 
              helperText={confirmPasswordHelperText}
            />
            <Box sx={{ height: 40 }} />
          </>
        )}
        {errorMessage && (
          <Box sx={{ color: '#a2423d', marginBottom: 2 }}>
            {errorMessage}
          </Box>
        )}
        {successMessage && (
          <Box sx={{ marginBottom: 2, textAlign: 'center' }}>
            <Box>
              {successMessage} 
            </Box>
            <Box sx={{ height: 40 }} />
            <Link to="/login" style={{ color: '#006666', textDecoration: 'none' }}>
              Login
            </Link>
          </Box>
        )}
        {!successMessage && (
          <>
            <CustomButtonWide text="Set New Password" onClick={handleNewPassword} disabled={!isFormValid} />
            <Box sx={{ height: 40 }} />
          </>
        )}
      </Box>
    </PaddedCentralBox>
  );
};

export default NewPassword;