import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Iframe from 'react-iframe';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import ProgressMobileStepper from './ProgressMobileStepper';
import Lesson from './Lesson';
import { useMediaQuery, Snackbar } from '@mui/material';
import { getAuth } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import Paragraph from './Paragraph';
import CompletionPieChart from './CompletionPieChart';
import IframeComponent from './IframeComponent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const { REACT_APP_ENDPOINT } = process.env;

const createSummaryLesson = (lessonDone, lessons, lessonSummary) => ({
  title: 'Summary',
  content: (
    <Stack spacing={2}>
      <Paragraph>{lessonSummary}</Paragraph>
      <Paragraph>{`You’ve successfully completed ${lessonDone.filter(done => done).length} out of ${lessons.length} challenges in this lesson.`}</Paragraph>
      <Paragraph>{`Feel free to go back and revisit the commands to improve your score, or you can choose to finish the exercise now.`}</Paragraph>
      <CompletionPieChart completed={lessonDone.filter(done => done).length} total={lessons.length} />
      <Box sx={{ height: 40 }} />
    </Stack>
  ),
  validation: {}
});

const generateRandomSession = () => {
  return Math.random().toString().slice(2, 10);
};

const CourseComponent = ({ lessons, script, lessonName, lessonSummary }) => {
  const history = useHistory();
  const [lesson, setLesson] = useState(0);
  const [lessonDone, setLessonDone] = useState(Array(lessons.length + 1).fill(false)); // Adjusted for new lesson
  const [userUUID, setUserUUID] = useState(null);
  const [ws, setWs] = useState(null);
  const [lessonsWithSummary, setLessonsWithSummary] = useState([]);
  const [session, setSession] = useState(generateRandomSession());
  const reducedWidth = useMediaQuery('(max-width:1200px)');
  const isMobile = useMediaQuery('(max-width:600px)');
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  // Initialize lessonsWithSummary with the new "Finished" lesson
  useEffect(() => {
    const updatedLessons = [...lessons, createSummaryLesson(lessonDone, lessons, lessonSummary)];
    setLessonsWithSummary(updatedLessons);
  }, [lessons, lessonDone]);

  useEffect(() => {
    if (isMobile) {
      setSnackbarOpen(true);
    }
  }, [isMobile]);

  const nextLesson = useCallback(() => {
    setLesson((prevLesson) => (prevLesson < lessonsWithSummary.length - 1 ? prevLesson + 1 : prevLesson));
  }, [lessonsWithSummary.length]);

  const prevLesson = useCallback(() => {
    setLesson((prevLesson) => (prevLesson > 0 ? prevLesson - 1 : prevLesson));
  }, []);

  const processConsole = useCallback((data) => {
    const { commandNeeded, arguments: args, type, matchInputHex, matchOutputHex } = lessonsWithSummary[lesson].validation;
  
    switch (type) {
      case 'command_needed':
        if (data.length >= 3) {
          const [lastCommand] = data.slice(-3);
          let commandValid = false;
  
          if (commandNeeded && lastCommand.Commandpath === commandNeeded) {
            commandValid = true;
          }
  
          if (args && args.every(arg => lastCommand.Args.includes(arg))) {
            commandValid = true;
          }
  
          if (commandValid) {
            setLessonDone((prevLessonDone) => {
              const newLessonDone = [...prevLessonDone];
              newLessonDone[lesson] = true;
              return newLessonDone;
            });
          }
        }
        break;
      case 'input_output_matches':
          if (data.length >= 3) {
            const [lastOutput] = data.slice(-2);
            const [lastInput] = data.slice(-1);
            let matchValid = false;
    
            if (lastOutput && lastInput) {
              const parseHex = (hexString) => (typeof hexString === 'string' ? hexString.match(/.{1,2}/g) : []) || [];
              const parsedLastInput = parseHex(lastInput.Data);
              const parsedLastOutput = parseHex(lastOutput.Data);

              if (matchInputHex && parsedLastInput.includes(matchInputHex)) {
                matchValid = true;
              }
    
              if (matchOutputHex && parsedLastOutput.includes(matchOutputHex)) {
                matchValid = true;
              }
            }

            if (matchValid) {
              setLessonDone((prevLessonDone) => {
                const newLessonDone = [...prevLessonDone];
                newLessonDone[lesson] = true;
                return newLessonDone;
              });
            }
          }
          break;
      case 'textfiled_matches_exec':
        if (data.stdout) {
          const textFieldShouldMatch = data.stdout.trim();
          const textFiledText = document.getElementById(lessonsWithSummary[lesson].validation.textFiledId).value;
          if (textFieldShouldMatch === textFiledText) {
            setLessonDone((prevLessonDone) => {
              const newLessonDone = [...prevLessonDone];
              newLessonDone[lesson] = true;
              return newLessonDone;
            });
          }
        }
        break;
      case 'radiobutton_matches_exec':
        if (data.stdout) {
          const radioButtonShouldMatch = data.stdout.trim();
          const radioElement = document.querySelector(`[name="${lessonsWithSummary[lesson].validation.formName}"]:checked`);
          if (radioElement && radioButtonShouldMatch === radioElement.value) {
            setLessonDone((prevLessonDone) => {
              const newLessonDone = [...prevLessonDone];
              newLessonDone[lesson] = true;
              return newLessonDone;
            });
          }
        }
        break;
      case 'exec_command':
        if (lessonsWithSummary[lesson].validation.exitCode === data.exitcode) {
          setLessonDone((prevLessonDone) => {
            const newLessonDone = [...prevLessonDone];
            newLessonDone[lesson] = true;
            return newLessonDone;
          });
        }
        break;
      default:
        break;
    }
  }, [lesson, lessonsWithSummary]);

  const fetchData = useCallback(async (url, body) => {
    const response = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    });
    return response.json();
  }, []);

  const loadData = useCallback(async () => {
    if (!userUUID) return;

    const { validation } = lessonsWithSummary[lesson];
    const url = `${REACT_APP_ENDPOINT}/api/environment/term/${userUUID}/exec`;
    const body = { command: validation.execCommand };

    switch (validation.type) {
      case 'radiobutton_matches_exec':
        if (ws) {
          ws.send(JSON.stringify({ type: 'exec_command', command: validation.execCommand }));
        }
        break;
      case 'textfiled_matches_exec':
        if (ws) {
          ws.send(JSON.stringify({ type: 'exec_command', command: validation.execCommand }));
        }
        break;
      case 'exec_command':
        if (ws) {
          ws.send(JSON.stringify({ type: 'exec_command', command: validation.execCommand }));
        }
        break;
      case 'command_needed':
        if (ws) {
          ws.send(JSON.stringify({ type: 'fetch_console_data' }));
        }
        break;
      case 'input_output_matches':
        if (ws) {
          ws.send(JSON.stringify({ type: 'fetch_console_data' }));
        }
        break;
      default:
        break;
    }
  }, [lesson, fetchData, userUUID, lessonsWithSummary, ws]);

  useEffect(() => {
    if (ws) {
      ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        processConsole(data);
      };
    }
  
    return () => {
      if (ws) {
        ws.onmessage = null;
      }
    };
  }, [ws, processConsole]);

  useEffect(() => {
    const interval = setInterval(loadData, 1000);
    return () => clearInterval(interval);
  }, [loadData]);

  useEffect(() => {
    const installEnvironment = async () => {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          const userUUID = userData.uuid;
          setUserUUID(userUUID);
          await fetch(`https://${REACT_APP_ENDPOINT}/api/environment/install`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ uuid: userUUID, command: script, session: session }),
          });

          const establishWebSocket = () => {
            const websocket = new WebSocket(`wss://${REACT_APP_ENDPOINT}/api/terminal/${userUUID}-${session}/data`);
            websocket.onopen = () => {
              setWs(websocket);
            };
            websocket.onerror = () => {
              setTimeout(establishWebSocket, 1000); // Retry after 1 second
            };
          };
  
          establishWebSocket();
        } else {
          console.error("User document does not exist");
        }
      }
    };

    installEnvironment();

    return async () => {
      if (userUUID) {
        await fetch(`https://${REACT_APP_ENDPOINT}/api/environment/delete`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ uuid: userUUID }),
        });
      }
      if (ws) {
        ws.close();
      }
    };
  }, [script]);

  const handleBackClick = () => {
    const currentPath = history.location.pathname;
    const newPath = currentPath.split('/').slice(0, -1).join('/');
    history.push(newPath);
  };

  useEffect(() => {
    const handleClick = (event) => {
      if (event.target.tagName === 'CODE') {
        navigator.clipboard.writeText(event.target.innerText);
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  const renderHeader = () => (
      <Box sx={{ display: 'flex', alignItems: 'center', paddingBottom: 3 }}>
        <Box onClick={handleBackClick} sx={{ cursor: 'pointer' }}>
          <PlayCircleOutlineIcon 
            sx={{ 
              marginRight: 2, 
              color: '#006666', 
              transform: 'rotate(180deg)', 
              fontSize: '3rem',
              '&:hover': {
                color: '#02a8a8'
              }
            }} 
          />
        </Box>
        <Typography variant="h1" sx={{ fontSize: 28, fontWeight: 'bold', color: '#333333'  }}>
          {lessonsWithSummary[lesson] ? `${lessonName} - ${lessonsWithSummary[lesson].title}${lesson < lessons.length ? ` - (${lesson + 1}/${lessons.length})` : ''}` : 'Loading...'}
        </Typography>
      </Box>
  );

  const renderLessonContent = (mobile) => (
    <>
      <Box sx={{ flexGrow: 1 }}>
        {mobile && <Box sx={{ height: 12 }} />}
        {lessonsWithSummary[lesson] && (
          <Lesson 
            done={lessonDone[lesson]} 
            hint={lessonsWithSummary[lesson].validation.hint} 
            showAlert={Object.keys(lessonsWithSummary[lesson].validation).length > 0}
          >
            {lessonsWithSummary[lesson].content}
            {mobile && <Box sx={{ height: 24 }} />}
          </Lesson>
        )}
      </Box>
      <Box sx={{ height: 20 }} />
      <ProgressMobileStepper 
        steps={lessonsWithSummary.length} 
        onNext={nextLesson} 
        onPrev={prevLesson} 
        sx={{ paddingTop: 8, flexGrow: 0 }}
      />
      {mobile && <Box sx={{ height: 24 }} />}
    </>
  );

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        {renderHeader()}
      </Grid>
        <>
          {!isMobile && 
            <Grid item xs={lesson === lessonsWithSummary.length - 1 ? 12 : 4} sx={{ paddingRight: reducedWidth ? 2 : 8, display: 'flex', flexDirection: 'column' }}>
              {renderLessonContent(false)}
            </Grid>
          }
          <Grid item xs={ isMobile ? 12 : 8 } sx={{ display: lesson === lessonsWithSummary.length - 1 ? 'none' : 'block' }}>
            <IframeComponent userUUID={userUUID} session={session} />
          </Grid>
          {isMobile &&
            <Grid item xs={12}>
              {renderLessonContent(true)}
            </Grid>
          }
        </>
        <Snackbar
          open={snackbarOpen}
          onClose={() => setSnackbarOpen(false)}
          message={
            <span style={{ color: 'white', fontFamily: 'Roboto', display: 'flex', alignItems: 'center' }}>
              For the best experience, we advise using the application on a desktop computer.
              <IconButton size="small" aria-label="close" color="inherit" onClick={() => setSnackbarOpen(false)} sx={{ marginLeft: 1 }}>
                <CloseIcon fontSize="medium" sx={{ color: 'white' }} />
              </IconButton>
            </span>
          }
          autoHideDuration={6000}
          ContentProps={{
            sx: {
              boxShadow: 'none',
              borderRadius: '8px',
              backgroundColor: '#006666',
            },
          }}
        />

    </Grid>
  );
};

export default CourseComponent;