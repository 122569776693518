import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import { CustomNextButton, CustomBackButton } from './CustomButton';
import { useHistory, useLocation } from 'react-router-dom';
import { getFirestore, doc, updateDoc, getDoc } from 'firebase/firestore';
import { auth } from '../firebaseConfig';

export default function ProgressMobileStepper({ steps, onNext, onPrev }) {
    const theme = useTheme();
    const history = useHistory();
    const location = useLocation();
    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = async () => {
        if (activeStep === steps - 1) {
            const db = getFirestore();
            const user = auth.currentUser;
            if (user) {
                const userDocRef = doc(db, 'users', user.uid);
                const currentLessonId = location.pathname.split('/').pop();
                const userDoc = await getDoc(userDocRef);
                let lessons = [];
    
                if (userDoc.exists()) {
                    const data = userDoc.data();
                    if (data.lessons) {
                        lessons = JSON.parse(data.lessons);
                    }
                }
    
                lessons.push(currentLessonId);
    
                await updateDoc(userDocRef, {
                    lessons: JSON.stringify(lessons)
                });
            }

            // Remove the last part of the URL
            const newPath = location.pathname.split('/').slice(0, -1).join('/');
            history.push(newPath);
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            onNext();
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        onPrev();
    };

    return (
        <MobileStepper
            variant="dots"
            steps={steps}
            position="static"
            activeStep={activeStep}
            sx={{ 
                //maxWidth: 400, 
                padding: 0, // Remove padding
                '& .css-1u1welh': { padding: 0 }, // Target specific class to remove padding
                '& .MuiMobileStepper-dots': { display: 'none' } // Hide dots
            }}
            nextButton={
                <CustomNextButton 
                    onClick={handleNext} 
                    disabled={false}
                    text={activeStep === steps - 1 ? "Finish" : "Next"}
                />
            }
            backButton={
                <CustomBackButton 
                    onClick={handleBack} 
                    disabled={activeStep === 0}
                />
            }
        />
    );
}