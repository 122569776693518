import React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Iframe from 'react-iframe';

const IframeComponent = ({ userUUID, session }) => {
  return (
    <Stack spacing={0} sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
      <Box sx={{ padding: 2, backgroundColor: '#333333', borderRadius: 2 }}>
        {userUUID && <Iframe url={`/wetty/ssh/${userUUID}-${session}`} height="550px" id="myId" position="relative" width="100%" style={{ maxWidth: '100%' }} />}
      </Box>
    </Stack>
  );
};

export default IframeComponent;