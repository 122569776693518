import React from 'react';
import { useHistory } from 'react-router-dom';
import './CustomButton.css';

export function CustomNextButton({ onClick, disabled, sx, text = "Next" }) {
    return (
        <button 
            onClick={onClick} 
            disabled={disabled} 
            className="custom-next-button"
            style={{ ...sx, marginLeft: '5px' }}
        >
            {text}
        </button>
    );
}

export function CustomBackButton({ onClick, disabled, sx }) {
    return (
        <button 
            onClick={onClick} 
            disabled={disabled} 
            className="custom-back-button"
            style={{ ...sx, marginRight: '5px' }}
        >
            Back
        </button>
    );
}

export function CustomOpenButton({ onClick, disabled, sx, route }) {
    const history = useHistory();

    const handleClick = () => {
        if (onClick) onClick();
        history.push(route);
    };

    return (
        <button 
            onClick={handleClick} 
            disabled={disabled} 
            className="custom-open-button"
            style={sx}
        >
            Open
        </button>
    );
}

export function CustomButton({ onClick, disabled, sx, text }) {
    return (
        <button 
            onClick={onClick} 
            disabled={disabled} 
            className="custom-button"
            style={sx}
        >
            {text}
        </button>
    );
}

export function CustomButtonWide({ onClick, disabled, sx, text }) {
    return (
        <button 
            onClick={onClick} 
            disabled={disabled} 
            className="custom-button-wide"
            style={sx}
        >
            {text}
        </button>
    );
}

export function CustomButtonLogin({ onClick, disabled, sx, text }) {
    return (
        <button 
            onClick={onClick} 
            disabled={disabled} 
            className="custom-button-login"
            style={sx}
        >
            {text}
        </button>
    );
}

export function CustomButtonExternalLoginProvider({ onClick, disabled, sx, text, icon }) {
    return (
        <button 
            onClick={onClick} 
            disabled={disabled} 
            className="custom-button-external-login-provider"
            style={sx}
        >
            {icon && <span className="button-icon">{icon}</span>}
            {text}
        </button>
    );
}