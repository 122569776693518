import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { useHistory } from 'react-router-dom';

const RouteTitle = ({ title, returnable = false }) => {
    const history = useHistory();

    const handleGoBack = () => {
        const pathArray = history.location.pathname.split('/');
        pathArray.pop();
        const newPath = pathArray.join('/') || '/';
        history.push(newPath);
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', paddingBottom: 3 }}>
            {returnable && (
                <div onClick={handleGoBack} style={{ color: 'inherit', textDecoration: 'none', cursor: 'pointer' }}>
                    <PlayCircleOutlineIcon 
                        sx={{ 
                            marginRight: 2, 
                            color: '#006666', 
                            transform: 'rotate(180deg)', 
                            fontSize: '3rem',
                            '&:hover': {
                                color: '#02a8a8'
                            }
                        }} 
                    />
                </div>
            )}
            <Typography variant="h1" sx={{ fontSize: 28, fontWeight: 'bold', color: '#333333' }}>
                {title}
            </Typography>
        </Box>
    );
};

export default RouteTitle;