import React from 'react';
import Alert from '@mui/material/Alert';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { styled } from '@mui/material/styles';

const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .MuiTooltip-tooltip`]: {
        backgroundColor: "#333333", // Solid color
        color: "#ffffff", // Text color
    },
    [`& .MuiTooltip-arrow`]: {
        color: "#333333", // Arrow color
    },
    [`& .MuiTooltip-tooltip div`]: {
        color: "#ffffff", // Ensure text inside div is white
    },
});

function Lesson({ done, children, hint, showAlert }) {

    var text;
    var severity;
    var icon;

    if (done) {
        text = "Task is done";
        severity = "success";
        icon = <CheckBoxOutlinedIcon />;
    } else {
        text = "Task is not done";
        severity = "info";
        icon = <CheckBoxOutlineBlankOutlinedIcon />;
    }

    return (
        <div className="lesson-container" style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <div style={{ flexGrow: 1 }}>
                {children}
            </div>
            {showAlert && (
                <Alert 
                    variant="outlined" 
                    severity={severity}
                    icon={icon}
                    sx={{ 
                        '& .MuiAlert-icon': {
                            color: '#006666'
                        },
                        borderColor: '#006666',
                        display: 'flex',
                        alignItems: 'center',
                        '& .MuiAlert-message': {
                            flex: 1
                        }
                    }}
                >
                    <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                        <span style={{ marginRight: '8px' }}>{text}</span>
                        <div style={{ flexGrow: 1 }}></div>
                        {hint && (
                            <CustomTooltip 
                                title={
                                    <React.Fragment>
                                        <div>{hint}</div>
                                    </React.Fragment>
                                }
                                arrow
                            >
                                <HelpOutlineIcon style={{ color: '#006666' }} />
                            </CustomTooltip>
                        )}
                    </div>
                </Alert>
            )}
        </div>
    );
}

export default Lesson;