import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { CustomOpenButton } from './CustomButton'; // Import the CustomNextButton component
import { useMediaQuery } from '@mui/material';

const ChapterModule = ({ title, content, route, disabled, number, line = "full", grayNumber = false, completed = false }) => {
    
    const isMobile = useMediaQuery('(max-width:600px)');
    
    return (
        <Box
            sx={{
                position: 'relative', // Ensure the Box is positioned relatively
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: 1,
                minHeight: 120
            }}
        >
            <Box
                sx={{
                    position: 'relative', // Ensure the Box is positioned relatively
                    display: 'flex',
                    flexDirection: 'row' }}
                > 
                <Box 
                    sx={{
                        position: 'relative', // Ensure the Box is positioned relatively
                        display: 'flex',
                        flexDirection: 'column' }}
                >
                    <Box
                        id="number"
                        sx={{
                            width: 50,
                            height: 50,
                            backgroundColor: grayNumber ? '#C4C4C4' : '#555555',
                            borderRadius: '50%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: 'white',
                            fontWeight: 'bold'
                        }}
                    >
                        {number}
                    </Box>
                    {line !== "none" && (
                        <Box
                            id="line"
                            sx={{
                                width: 2,
                                backgroundColor: line === "gray" ? '#C4C4C4' : '#555555',
                                minHeight: 90,
                                flexGrow: 1,
                                maxHeight: '100%',
                                margin: 'auto',
                                marginTop: 1
                            }}
                        />
                    )}
                </Box>

                <Box sx={{ paddingRight: isMobile ? 0 : 10, paddingLeft: 2 }}>
                    <h2 style={{ fontSize: 18, color: '#333333' }}>{title}</h2>
                    <p style={{ fontSize: 14, color: '#555555' }}>{content}</p>
                    <p style={{ fontSize: 14, color: '#333333', margin: 0 }}>
                            Completed: <span style={{ color: completed ? '#00739D' : '#A2423D' }}>{completed ? 'Yes' : 'No'}</span>
                    </p>
                    {isMobile && 
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingTop: 6, paddingBottom: 2  }}>
                            <CustomOpenButton route={route} disabled={disabled} sx={{ width: '100%'}} />
                        </Box>
                    }
                </Box>
            </Box>
            {!isMobile &&
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                    }}
                >
                    <Box sx={{ height: 14 }} />
                    <CustomOpenButton route={route} disabled={disabled} />
                </Box>
            }
        </Box>
    );
};

ChapterModule.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    route: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    number: PropTypes.number.isRequired,
    line: PropTypes.oneOf(['full', 'gray', 'none']),
    grayNumber: PropTypes.bool,
    completed: PropTypes.bool,
};

export default ChapterModule;