import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import RouteTitle from '../components/RouteTitle';
import PaddedBox from '../components/PaddedBox';
import ChapterModule from '../components/ChapterModule';
import { useParams } from 'react-router-dom';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { auth } from '../firebaseConfig'; // Import auth from firebaseConfig

const Chapter = () => {
    const { id } = useParams();
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [lessons, setLessons] = useState([]);
    const [completedLessons, setCompletedLessons] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchChapter = async () => {
            const db = getFirestore();
            const docRef = doc(db, 'modules', id);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const data = docSnap.data();
                setTitle(data.title);
                setDescription(data.description);

                const lessonIds = JSON.parse(data.lesson);
                const lessonPromises = lessonIds.map(async (lessonId) => {
                    const lessonRef = doc(db, 'lessons', lessonId);
                    const lessonSnap = await getDoc(lessonRef);
                    return lessonSnap.exists() ? { id: lessonId, ...lessonSnap.data() } : null;
                });

                const lessonsData = await Promise.all(lessonPromises);
                setLessons(lessonsData.filter(lesson => lesson !== null));
            } else {
                console.log('No such document!');
            }

            const user = auth.currentUser;
            if (user) {
                const userDocRef = doc(db, 'users', user.uid);
                const userDocSnap = await getDoc(userDocRef);

                if (userDocSnap.exists()) {
                    const userData = userDocSnap.data();
                    const completedLessonIds = JSON.parse(userData.lessons);
                    setCompletedLessons(completedLessonIds);
                }
            }

            setLoading(false);
        };

        fetchChapter();
    }, [id]);

    if (loading) {
        return <Box sx={{ paddingLeft: 10, paddingRight: 10, paddingTop: 3 }}>Loading...</Box>;
    }

    const completedCount = lessons.filter(lesson => completedLessons.includes(lesson.id)).length;
    const allCompleted = completedCount === lessons.length;

    let foundIncomplete = false;

    return (
        <PaddedBox>
            <RouteTitle title={title} returnable={true} />
            <Box sx={{ maxWidth: 600 }}>
                {description}
            </Box>
            <Box sx={{ height: 30 }} />
            <Box />
            <span style={{ color: '#333333' }}>Completed:</span> 
            <span style={{ color: allCompleted ? '#00739D' : '#A2423D' }}>
                {completedCount}/{lessons.length}
            </span>
            <Box />
            <Box sx={{ height: 30 }} />
            <Box sx={{ backgroundColor: '#EDEDED', borderRadius: 2, padding: 3 }}>
                {lessons.map((lesson, index) => {
                    const isLastLesson = index === lessons.length - 1;
                    const isCompleted = completedLessons.includes(lesson.id);

                    if (!isCompleted) {
                        foundIncomplete = true;
                    }

                    const line = isLastLesson ? "none" : (foundIncomplete ? "gray" : "full");

                    return (
                        <ChapterModule
                            key={index}
                            number={index + 1}
                            title={lesson.name}
                            content={lesson.description}
                            route={`/lesson${index + 1}`}
                            line={line}
                            completed={isCompleted}
                        />
                    );
                })}
            </Box>
        </PaddedBox>
    );
};

export default Chapter;