import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import RouteTitle from '../components/RouteTitle';
import ConfigurableField from '../components/ConfigurableField';
import { CustomButtonWide } from '../components/CustomButton';
import { Link, useHistory } from "react-router-dom";
import PaddedCentralBox from '../components/PaddedCentralBox';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../firebaseConfig';

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [emailHelperText, setEmailHelperText] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setIsFormValid(validateEmail(email));
  }, [email]);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const getFriendlyErrorMessage = (errorCode) => {
    switch (errorCode) {
      case 'auth/invalid-email':
        return 'The email address is not valid. Please enter a valid email.';
      case 'auth/user-not-found':
        return 'No user found with this email. Please check the email and try again.';
      default:
        return 'An unexpected error occurred. Please try again later.';
    }
  };

  const handleResetPassword = async () => {
    setErrorMessage('');
    setSuccessMessage('');
    if (!validateEmail(email)) {
      setEmailHelperText('Invalid email format');
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      setSuccessMessage('Password reset link has been sent to your email.');
    } catch (error) {
      const friendlyMessage = getFriendlyErrorMessage(error.code);
      setErrorMessage(friendlyMessage);
      console.error("Error resetting password: ", error);
    }
  };

  return (
    <PaddedCentralBox>
      <Box sx={{ width: 325 }}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <RouteTitle title="Reset Password" />
        </Box>
        <Box sx={{ height: 40 }} />
        {!successMessage && (
          <>
            <ConfigurableField 
              label="Email:" 
              textFieldId="email" 
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailHelperText(validateEmail(e.target.value) ? '' : 'Invalid email format');
              }} 
              helperText={emailHelperText} 
            />
            <Box sx={{ height: 40 }} />
          </>
        )}
        {errorMessage && (
          <Box sx={{ color: '#a2423d', marginBottom: 2 }}>
            {errorMessage}
          </Box>
        )}
        {successMessage && (
          <Box sx={{ marginBottom: 2, textAlign: 'center' }}>
            {successMessage}
          </Box>
        )}
        {!successMessage && (
          <>
            <CustomButtonWide text="Reset Password" onClick={handleResetPassword} disabled={!isFormValid} />
            <Box sx={{ height: 40 }} />
          </>
        )}
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <p>
            {successMessage ? (
              <Link to="/login" style={{ color: '#006666', textDecoration: 'none' }}>
                Login
              </Link>
            ) : (
              <>
                Remembered your password?{' '}
                <Link to="/login" style={{ color: '#006666', textDecoration: 'none' }}>
                  Login
                </Link>
              </>
            )}
          </p>
        </Box>
        <Box sx={{ height: 40 }} />
      </Box>
    </PaddedCentralBox>
  );
};

export default ResetPassword;