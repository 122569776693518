// src/firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyDEa_C36bwOh4X_1fompMVejQoXu7t4EXk",
//    authDomain: "learncmd-24b2d.firebaseapp.com",
    authDomain: "auth.learncmd.io",
    projectId: "learncmd-24b2d",
    storageBucket: "learncmd-24b2d.appspot.com",
    messagingSenderId: "644592274294",
    appId: "1:644592274294:web:5cefca968e41fb373f0857"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };