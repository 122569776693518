import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs, query, where, orderBy, doc, getDoc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Module from '../components/Module';
import PaddedBox from '../components/PaddedBox';
import WelcomeMessage from '../components/Snippets';

const Modules = ({ type, title, customMessage }) => {
    const [modules, setModules] = useState([]);
    const [user, setUser] = useState(null);
    const [isPremium, setIsPremium] = useState(true);
    const [completedLessons, setCompletedLessons] = useState([]);

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                setUser(user);
                const userDoc = await getDoc(doc(getFirestore(), 'users', user.uid));
                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    if (userData.premium) {
                        setIsPremium(true);
                    } else {
                        setIsPremium(false);
                    }
                    if (userData.lessons) {
                        setCompletedLessons(JSON.parse(userData.lessons));
                    }
                }
                fetchModules();
            } else {
                setUser(null);
            }
        });

        return () => unsubscribe();
    }, [type]);

    const fetchModules = async () => {
        const db = getFirestore();
        const modulesCollection = collection(db, 'modules');
        const q = query(modulesCollection, where('type', '==', type), orderBy('number'));
        const modulesSnapshot = await getDocs(q);
        const modulesList = modulesSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
        setModules(modulesList);
    };

    const getCompletedText = (module) => {
        let text = "NOT COMPLETED";
        let color = "#A2423D";

        if (module.type === 'walkthrough') {
            if (completedLessons.includes(module.lesson)) {
                text = "Completed";
                color = "#00739D";
            } else {
                text = "Not Completed";
            }
        } else if (module.type === 'module') {
            const lessonsArray = JSON.parse(module.lesson);
            const completedCount = lessonsArray.filter(lesson => completedLessons.includes(lesson)).length;
            text = `${completedCount}/${lessonsArray.length} completed`;
            if (completedCount === lessonsArray.length) {
                color = "#00739D";
            }
        }

        return { text, color };
    };

    return (
        <PaddedBox title={title} showBanner={!isPremium}>
            {customMessage}
            {modules.map((module, i) => (
                <React.Fragment key={i}>
                    <Module 
                        title={module.title} 
                        content={module.description} 
                        route={module.type === 'module' ? `module/${module.id}` : `${type === 'module' ? 'course' : type}/${module.lesson}`} 
                        disabled={!isPremium && module.premium}
                        completedText={getCompletedText(module)}
                    />
                </React.Fragment>
            ))}
        </PaddedBox>
    );
};

export default Modules;