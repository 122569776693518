import React from 'react';
import { Box, Stack, FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import LinuxCommand from './LinuxCommand';
import CommandSnippet from './CommandSnippet';
import StyledTextField from './StyledTextField';
import Paragraph from './Paragraph';

const lessonsData = [
    {
        "title": "ps command",
        "validation": { "type": "command_needed", "commandNeeded": "/usr/bin/ps" },
        "content": [
            { "type": "Paragraph", "text": "ps command is used to list the processes. Try it out:" },
            { "type": "LinuxCommand", "command": "ps" },
            { "type": "Paragraph", "text": "You can see following processes running on your system" },
            { "type": "CommandSnippet", "text": "PID TTY TIME CMD <br>250 pts/1 00: 00: 00 bash <br>255 pts/1 00: 00: 00 ps" },
            { "type": "Paragraph", "text": "This is what each column is:<ul><li>PID - the number of the process</li><li>TTY - the name of the console that the user is logged into</li><li>TIME- the amount of CPU in minutes and seconds that the process has been running</li><li>CMD - the name of the command that launched the process</li></ul>" }
        ]
    },
    {
        "title": "ps command",
        "validation": { "type": "textfiled_matches_exec", "textFiledId": "bash_pid_1", "execCommand": "pgrep bash" },
        "content": [
            { "type": "Paragraph", "text": "Enter the PID of your bash session" },
            { "type": "StyledTextField", "id": "bash_pid_1" }
        ]
    },
    {
        "title": "top command",
        "validation": { "type": "command_needed", "commandNeeded": "/usr/bin/top" },
        "content": [
            { "type": "Paragraph", "text": "top command is used to resource usage of the processes. Try it out:" },
            { "type": "LinuxCommand", "command": "top" }
        ]
    },
    {
        "title": "uname command",
        "validation": { "type": "command_needed", "commandNeeded": "/usr/bin/uname" },
        "content": [
            { "type": "Paragraph", "text": "Uname command is used to check Linux kernel version" },
            { "type": "LinuxCommand", "command": "uname -a" }
        ]
    },
    {
        "title": "uname command",
        "validation": { "type": "radiobutton_matches_exec", "formName": "kernel_version", "execCommand": "uname -r | cut -d '-' -f1" },
        "content": [
            { "type": "Paragraph", "text": "Select current kernel version" },
            { "type": "RadioButtons", "name": "kernel_version", "values": ["5.11.0", "5.12.0", "5.13.0"] }
        ]
    },
    {
        "title": "touch command",
        "validation": { "type": "exec_command", "execCommand": "stat /root/test", "exitCode": 0 },
        "content": [
            { "type": "Paragraph", "text": "Create file test in /root directory" }
        ]
    },
    {
        "title": "rm command",
        "validation": { "type": "exec_command", "execCommand": "stat /root/test", "exitCode": 1 },
        "content": [
            { "type": "Paragraph", "text": "Delete file test in /root directory" }
        ]
    },
    {
        "title": "grep command",
        "validation": { "type": "command_needed", "commandNeeded": "/usr/bin/grep" },
        "content": [
            { "type": "Paragraph", "text": "The 'grep' command is used to search for patterns in files. Let's try it by searching for the word 'root' in /etc/passwd:" },
            { "type": "LinuxCommand", "command": "grep 'root' /etc/passwd" },
            { "type": "Paragraph", "text": "You should see output that highlights lines containing the word 'root'. Here's an example:" },
            { "type": "CommandSnippet", "text": "root:x:0:0:root:/root:/bin/bash<br>..." },
            { "type": "Paragraph", "text": "The command output contains matching lines from the file. Here’s a breakdown of each component:<ul><li>'grep' - the command to search for patterns</li><li>'root' - the search pattern enclosed in quotes</li><li>'/etc/passwd' - the file to search within</li></ul>" }
        ]
    },
    {
        "title": "grep command with options",
        "validation": { "type": "command_needed", "commandNeeded": "/usr/bin/grep" },
        "content": [
            { "type": "Paragraph", "text": "Now, let's use 'grep' with an option to ignore case sensitivity. Try searching for 'root' in a case-insensitive way:" },
            { "type": "LinuxCommand", "command": "grep -i 'root' /etc/passwd" },
            { "type": "Paragraph", "text": "This should return results even if 'root' is written in uppercase or lowercase letters. Here's how it works:<ul><li>'-i' - makes the search case-insensitive</li></ul>" }
        ]
    },
    {
        "title": "grep command validation",
        "validation": { "type": "textfiled_matches_exec", "textFiledId": "grep_test", "execCommand": "grep 'bash' /etc/passwd" },
        "content": [
            { "type": "Paragraph", "text": "Enter the number of lines containing the word 'bash' in the /etc/passwd file:" },
            { "type": "StyledTextField", "id": "grep_test" }
        ]
    },
    {
        "title": "grep with multiple patterns",
        "validation": { "type": "command_needed", "commandNeeded": "/usr/bin/grep" },
        "content": [
            { "type": "Paragraph", "text": "You can search for multiple patterns using the '-e' option. Try searching for both 'bash' and 'nologin' in /etc/passwd:" },
            { "type": "LinuxCommand", "command": "grep -e 'bash' -e 'nologin' /etc/passwd" },
            { "type": "Paragraph", "text": "This command will return lines containing either 'bash' or 'nologin'. The '-e' flag allows you to specify multiple search patterns." }
        ]
    },
    {
        "title": "grep and regular expressions",
        "validation": { "type": "exec_command", "execCommand": "grep -E '^[a-z]' /etc/passwd", "exitCode": 0 },
        "content": [
            { "type": "Paragraph", "text": "You can use regular expressions with 'grep' to perform more advanced searches. Try searching for lines that start with a lowercase letter in /etc/passwd:" },
            { "type": "LinuxCommand", "command": "grep -E '^[a-z]' /etc/passwd" },
            { "type": "Paragraph", "text": "In this case, '-E' enables extended regular expressions, and the pattern '^[a-z]' searches for lines that start with a lowercase letter." }
        ]
    }
];

const componentsMap = {
    Paragraph: ({ text }) => <Paragraph children={text} />,
    LinuxCommand: ({ command }) => <LinuxCommand command={command} />,
    CommandSnippet: ({ text }) => <CommandSnippet children={text} />,
    Radio: Radio,
    RadioGroup: RadioGroup,
    FormControlLabel: FormControlLabel,
    FormControl: FormControl,
    StyledTextField: StyledTextField,
    RadioButtons: ({ name, values }) => (
        <FormControl>
            <RadioGroup name={name}>
                {values.map((value) => (
                    <FormControlLabel
                        key={value}
                        value={value}
                        control={<Radio sx={{ "&.Mui-checked .MuiSvgIcon-root": { color: "#006666" } }} />}
                        label={value}
                    />
                ))}
            </RadioGroup>
        </FormControl>
    )
};

const renderStyledTextField = ({ id }) => <StyledTextField id={id} />;

const createElement = (element) => {
    const { type, command, id, name, values, props, children, text } = element;
    const Component = componentsMap[type];

    // Handle the control prop separately
    if (type === 'FormControlLabel' && props.control) {
        props.control = createElement(props.control);
    }

    // Pass command directly if type is LinuxCommand
    if (type === 'LinuxCommand') {
        return <Component command={command} />;
    }

    // Handle StyledTextField separately
    if (type === 'StyledTextField') {
        return renderStyledTextField({ id });
    }

    // Handle CommandSnippet separately
    if (type === 'CommandSnippet') {
        return <Component text={text} />;
    }

    if (type === 'Paragraph') {
        return <Component text={text} />;
    }

    // Handle RadioButtons separately
    if (type === 'RadioButtons') {
        return <Component name={name} values={values} />;
    }

    return (
        <Component {...props}>
            {Array.isArray(children) ? children.map(createElement) : children}
        </Component>
    );
};

export const parseLessons = (lessonsData) => {
    return lessonsData.map((lesson, index) => ({
        title: lesson.title,
        validation: lesson.validation,
        content: (
            <Stack spacing={2} key={index}>
                {lesson.content.map((element, idx) => createElement({ ...element, key: idx }))}
            </Stack>
        )
    }));
};

const lessons = parseLessons(lessonsData);

export default lessons;