import React, { useState } from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Link,
} from "react-router-dom";

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Backdrop from '@mui/material/Backdrop';
import { styled } from '@mui/material/styles';

import TerminalOutlinedIcon from '@mui/icons-material/TerminalOutlined';
import NearMeOutlinedIcon from '@mui/icons-material/NearMeOutlined';
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';

import Header from './components/Header';
import Lesson from './routes/Lesson';
import Modules from './routes/Modules';
import Materials from './routes/Materials';
import Profile from './routes/Profile';
import Security from './routes/Security';
import Login from './routes/Login';
import Signup from './routes/Signup';
import ResetPassword from './routes/ResetPassword';
import Markdown from './routes/Markdown';
import PrivateRoute from './components/PrivateRoute';
import Chapter from './routes/Chapter';
import { WelcomeMessage, WalkthroughMessage } from './components/Snippets'; 
import NewPassword from './routes/NewPassword'; 

const CustomBackdrop = styled(Backdrop)(({ theme }) => ({
    zIndex: theme.zIndex.drawer - 1,
    top: '58px', // Adjust the top value to match the height of your header
}));

const HEADER_HEIGHT = '58px'; // Define a fixed height for the header

function App() {
    const [drawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    return (
        <Router>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
                <Box sx={{ height: HEADER_HEIGHT, flexShrink: 0 }}>
                    <Header showBackToMenuLink={false} toggleDrawer={toggleDrawer} />
                </Box>
                <Drawer
                    anchor="left"
                    open={drawerOpen}
                    onClose={toggleDrawer(false)}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    BackdropComponent={CustomBackdrop} // Use the custom backdrop
                    sx={{ '& .MuiDrawer-paper': { top: HEADER_HEIGHT } }} // Adjust the top value to match the height of your header
                >
                    <Box
                        sx={{ width: 250 }}
                        role="presentation"
                        onClick={toggleDrawer(false)}
                        onKeyDown={toggleDrawer(false)}
                    >
                        <List>
                            <ListItem 
                                button 
                                component={Link} 
                                to="/" 
                                sx={{ 
                                    '&:hover': { 
                                        backgroundColor: '#c6dada', 
                                        '& .MuiListItemText-primary': { 
                                            color: '#006666' 
                                        },
                                        '& .MuiListItemIcon-root': {
                                            color: '#006666'
                                        }
                                    } 
                                }}
                            >
                                <ListItemIcon sx={{ minWidth: 40 }}>
                                    <TerminalOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText primary="Course" />
                            </ListItem>
                            <ListItem 
                                button 
                                component={Link} 
                                to="/walkthrough" 
                                sx={{ 
                                    '&:hover': { 
                                        backgroundColor: '#c6dada', 
                                        '& .MuiListItemText-primary': { 
                                            color: '#006666' 
                                        },
                                        '& .MuiListItemIcon-root': {
                                            color: '#006666'
                                        }
                                    } 
                                }}
                            >
                                <ListItemIcon sx={{ minWidth: 40 }}>
                                    <NearMeOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText primary="Walkthroughs" />
                            </ListItem>
                            <ListItem 
                                button 
                                component={Link} 
                                to="/article" 
                                sx={{ 
                                    '&:hover': { 
                                        backgroundColor: '#c6dada', 
                                        '& .MuiListItemText-primary': { 
                                            color: '#006666' 
                                        },
                                        '& .MuiListItemIcon-root': {
                                            color: '#006666'
                                        }
                                    } 
                                }}
                            >
                                <ListItemIcon sx={{ minWidth: 40 }}>
                                    <BookOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText primary="Articles" />
                            </ListItem>
                            <ListItem 
                                button 
                                component={Link} 
                                to="/cheatsheet" 
                                sx={{ 
                                    '&:hover': { 
                                        backgroundColor: '#c6dada', 
                                        '& .MuiListItemText-primary': { 
                                            color: '#006666' 
                                        },
                                        '& .MuiListItemIcon-root': {
                                            color: '#006666'
                                        }
                                    } 
                                }}
                            >
                                <ListItemIcon sx={{ minWidth: 40 }}>
                                    <ArticleOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText primary="Cheatsheets" />
                            </ListItem>
                        </List>
                    </Box>
                </Drawer>
                <Container maxWidth={false} disableGutters sx={{ flex: 1, paddingLeft: 0, paddingRight: 0 }}>
                    <Switch>
                        <PrivateRoute exact path="/module/:moduleId/:id" component={Lesson} />
                        <PrivateRoute exact path="/walkthrough/:id" component={Lesson} />
                        <PrivateRoute exact path="/" component={(props) => <Modules {...props} title="Course" type="module" customMessage={<WelcomeMessage name="Alen Caljkusic" />} />} />
                        <PrivateRoute exact path="/module" component={(props) => <Modules {...props} title="Course" type="module" customMessage={<WelcomeMessage name="Alen Caljkusic" />} />} />
                        <PrivateRoute exact path="/walkthrough" component={(props) => <Modules {...props} title="Walkthroughs" type="walkthrough" customMessage={<WalkthroughMessage/>} />} />
                        <PrivateRoute exact path="/article" component={(props) => <Materials {...props} title="Articles" type="article" />} />
                        <PrivateRoute exact path="/cheatsheet" component={(props) => <Materials {...props} title="Cheatsheets" type="cheatsheet" />} />
                        <PrivateRoute exact path="/profile" component={Profile} />
                        <PrivateRoute exact path="/security" component={Security} />
                        <Route exact path="/login" component={Login} />
                        <Route exact path="/signup" component={Signup} />
                        <Route exact path="/reset-password" component={ResetPassword} />
                        <PrivateRoute exact path="/cheatsheet" component={Markdown} />
                        <PrivateRoute exact path="/article/:id" component={Markdown} />
                        <PrivateRoute exact path="/cheatsheet/:id" component={Markdown} />
                        <PrivateRoute exact path="/module/:id" component={Chapter} />
                        <Route exact path="/new-password" component={NewPassword} />
                    </Switch>
                </Container>
            </Box>
            <Box sx={{ height: 20 }}/>
        </Router>
    );
}

export default App;