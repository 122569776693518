import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import PaddedBox from '../components/PaddedBox';
import Box from '@mui/material/Box';
import RouteTitle from '../components/RouteTitle';
import MarkdownComponent from '../components/MarkdownComponent';

const Cheatsheets = () => {
    const { id } = useParams();
    const [markdownContent, setMarkdownContent] = useState('');
    const [title, setTitle] = useState('');

    useEffect(() => {
        const fetchContent = async () => {
            const db = getFirestore();
            const docRef = doc(db, 'materials', id);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const data = docSnap.data();
                const encodedContent = data.content;
                const decodedContent = atob(encodedContent);
                setMarkdownContent(decodedContent);
                setTitle(data.title); // Set the title
            } else {
                console.log('No such document!');
            }
        };

        fetchContent();
    }, [id]);

    return (
        <PaddedBox>
            <RouteTitle title={title} returnable={true} />
            <MarkdownComponent markdownContent={markdownContent} />
        </PaddedBox>
    );
};

export default Cheatsheets;