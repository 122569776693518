import React, { useState, useEffect } from 'react';
import { Link, useLocation, useHistory } from "react-router-dom";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import Person2Icon from '@mui/icons-material/Person2';
import Logo from '../assets/svgs/Logo.svg';
import HamburgerIcon from '../assets/svgs/Hamburger.svg';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';

function Header({ showBackToMenuLink, toggleDrawer }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [user, setUser] = useState(null);
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(user);
            } else {
                setUser(null);
            }
        });

        return () => unsubscribe();
    }, []);

    const handleAvatarClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        const auth = getAuth();
        signOut(auth).then(() => {
            handleClose();
            history.push('/login');
        }).catch((error) => {
            console.error("Error signing out: ", error);
        });
    };

    return (
        <Box sx={{ backgroundColor: '#006666', height: 58, paddingX: 0 }}>
            <Stack direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ height: '100%' }}
            >
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: 16 }}>
                    {user && (
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={toggleDrawer(true)}
                            disableRipple
                            sx={{ 
                                '&:hover': { backgroundColor: 'transparent' }, // Disable hover color change
                                transition: 'none' // Disable animation
                            }}
                        >
                            <img src={HamburgerIcon} alt="Menu" style={{ width: 24, height: 24, marginRight: 8 }} />
                        </IconButton>
                    )}
                    <Link to="/" style={{ height: 24 }}>
                        <img src={Logo} alt="Learn Linux Logo" style={{ height: 24, width: 135 }} />
                    </Link>
                </div>
                {user && (
                    <div style={{ display: 'flex', alignItems: 'center', marginRight: 16 }}>
                        <IconButton 
                            color="inherit" 
                            onClick={handleAvatarClick}
                            disableRipple
                            sx={{ 
                                '&:hover': { backgroundColor: '#c6dada' }, // Disable hover color change
                                transition: 'none',
                                backgroundColor: '#c6dada',
                                width: 30,
                                height: 30
                            }}
                        >
                            <Person2Icon style={{ fontSize: 22, color: '#006666' }} />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem 
                                onClick={() => { handleClose(); history.push('/profile'); }}
                                sx={{ 
                                    '&:hover': { 
                                        backgroundColor: '#c6dada', 
                                        '& .MuiSvgIcon-root, & .MuiTypography-root': { 
                                            color: '#006666' 
                                        } 
                                    } 
                                }}
                            >
                                <Box sx={{ minWidth: 30, display: 'flex', alignItems: 'center' }}>
                                    <ManageAccountsOutlinedIcon style={{ fontSize: 20 }} />
                                </Box>
                                <Typography sx={{ color: 'inherit' }}>Profile</Typography>
                            </MenuItem>
                            <MenuItem 
                                onClick={() => { handleClose(); history.push('/security'); }}
                                sx={{ 
                                    '&:hover': { 
                                        backgroundColor: '#c6dada', 
                                        '& .MuiSvgIcon-root, & .MuiTypography-root': { 
                                            color: '#006666' 
                                        } 
                                    } 
                                }}
                            >
                                <Box sx={{ minWidth: 30, display: 'flex', alignItems: 'center' }}>
                                    <LockOutlinedIcon style={{ fontSize: 20 }} />
                                </Box>
                                <Typography sx={{ color: 'inherit' }}>Security</Typography>
                            </MenuItem>
                            <Divider sx={{ '&.MuiDivider-root': { mt: 0, mb: 0 } }} />
                            <MenuItem 
                                onClick={handleLogout}
                                sx={{
                                    '&:hover': { 
                                        backgroundColor: '#c6dada', 
                                        '& .MuiSvgIcon-root, & .MuiTypography-root': { 
                                            color: '#006666' 
                                        } 
                                    } 
                                }}
                            >
                                <Box sx={{ minWidth: 30, display: 'flex', alignItems: 'center' }}>
                                    <LogoutOutlinedIcon style={{ fontSize: 20 }} />
                                </Box>
                                <Typography sx={{ color: 'inherit' }}>Logout</Typography>
                            </MenuItem>
                        </Menu>
                    </div>
                )}
            </Stack>
        </Box>
    );
}

export default Header;