import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs, query, where, orderBy, doc, getDoc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Box from '@mui/material/Box';
import RouteTitle from '../components/RouteTitle';
import Material from '../components/Material';
import PaddedBox from '../components/PaddedBox';

const Materials = ({ title, type }) => {
    const [materials, setMaterials] = useState([]);
    const [user, setUser] = useState(null);
    const [isPremium, setIsPremium] = useState(true);

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                setUser(user);
                const userDoc = await getDoc(doc(getFirestore(), 'users', user.uid));
                if (userDoc.exists() && userDoc.data().premium) {
                    setIsPremium(true);
                } else {
                    setIsPremium(false);
                }
                fetchMaterials();
            } else {
                setUser(null);
            }
        });

        return () => unsubscribe();
    }, [type]);

    const fetchMaterials = async () => {
        const db = getFirestore();
        const materialsCollection = collection(db, 'materials');
        const q = query(materialsCollection, where('type', '==', type), orderBy('number'));
        const materialsSnapshot = await getDocs(q);
        const materialsList = materialsSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
        setMaterials(materialsList);
    };

    return (
        <PaddedBox title={title} showBanner={!isPremium}>
            {materials.map((material, i) => (
                <Material 
                    key={i} 
                    title={material.title} 
                    content={material.description} 
                    link={`/${type}/${material.id}`} 
                    disabled={!isPremium && material.premium}
                />
            ))}
        </PaddedBox>
    );
};

export default Materials;