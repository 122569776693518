// src/routes/Login.js
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import RouteTitle from '../components/RouteTitle';
import ConfigurableField from '../components/ConfigurableField';
import { CustomButtonLogin, CustomButtonExternalLoginProvider } from '../components/CustomButton';
import { Link, useHistory } from "react-router-dom";
import PaddedCentralBox from '../components/PaddedCentralBox';
import { signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, GithubAuthProvider } from 'firebase/auth';
import { auth, db } from '../firebaseConfig';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import GoogleIcon from '@mui/icons-material/Google';
import GitHubIcon from '@mui/icons-material/GitHub';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();

  const getFriendlyErrorMessage = (errorCode) => {
    switch (errorCode) {
      case 'auth/user-not-found':
        return 'No user found with this email. Please sign up first.';
      case 'auth/wrong-password':
        return 'Incorrect password. Please try again.';
      case 'auth/invalid-email':
        return 'The email address is not valid. Please enter a valid email.';
      case 'auth/invalid-credential':
        return 'The credentials are not valid. Please try again.';
      case 'auth/account-exists-with-different-credential':
        return 'An account already exists with the same email address but different sign-in credentials. Please use a different sign-in method.';
      default:
        return 'An unexpected error occurred. Please try again later.';
    }
  };

  const handleLogin = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      history.push('/'); // Redirect to home page after successful login
    } catch (error) {
      const friendlyMessage = getFriendlyErrorMessage(error.code);
      setErrorMessage(friendlyMessage); // Set friendly error message
      console.error("Error logging in: ", error);
    }
  };

  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const userCredential = await signInWithPopup(auth, provider);
      const user = userCredential.user;

      // Check if the user is signing in for the first time
      const userDocRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userDocRef);

      if (!userDoc.exists()) {
        // Generate a UUID
        const uuid = uuidv4();

        // Save user details in Firestore
        await setDoc(userDocRef, {
          firstName: user.displayName.split(' ')[0],
          lastName: user.displayName.split(' ').slice(1).join(' '),
          email: user.email,
          uuid // Add UUID to user details
        });
      }

      history.push('/'); // Redirect to home page after successful login
    } catch (error) {
      const friendlyMessage = getFriendlyErrorMessage(error.code);
      setErrorMessage(friendlyMessage); // Set friendly error message
      console.error("Error logging in with Google: ", error);
    }
  };

  const handleGithubLogin = async () => {
    const provider = new GithubAuthProvider();
    try {
      const userCredential = await signInWithPopup(auth, provider);
      const user = userCredential.user;

      // Check if the user is signing in for the first time
      const userDocRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userDocRef);

      if (!userDoc.exists()) {
        // Generate a UUID
        const uuid = uuidv4();

        // Save user details in Firestore
        await setDoc(userDocRef, {
          firstName: user.displayName.split(' ')[0],
          lastName: user.displayName.split(' ').slice(1).join(' '),
          email: user.email,
          uuid // Add UUID to user details
        });
      }

      history.push('/'); // Redirect to home page after successful login
    } catch (error) {
      const friendlyMessage = getFriendlyErrorMessage(error.code);
      setErrorMessage(friendlyMessage); // Set friendly error message
      console.error("Error logging in with GitHub: ", error);
    }
  };

  return (
    <PaddedCentralBox>
      <Box sx={{ width: 325 }}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <RouteTitle title="Log In" />
        </Box>
        <Box sx={{ height: 40 }} />
        <ConfigurableField label="Email:" textFieldId="email" onChange={(e) => setEmail(e.target.value)} />
        <ConfigurableField label="Password:" textFieldId="password" type="password" onChange={(e) => setPassword(e.target.value)} />
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <p>
            Forgot your password?{' '}
            <Link to="/reset-password" style={{ color: '#006666', textDecoration: 'none' }}>
              Reset Password
            </Link>
          </p>
        </Box>
        <Box sx={{ height: 40 }} />
          {errorMessage && (
            <Box sx={{ color: '#a2423d', marginBottom: 6 }}>
              {errorMessage}
            </Box>
          )}
        <CustomButtonLogin text="Log In" onClick={handleLogin} />
        <Box sx={{ height: 10 }} />
        <Box sx={{ display: 'flex', alignItems: 'center', my: 2 }}>
          <Box sx={{ flex: 1, height: '1px', backgroundColor: '#555555' }} />
          <Box sx={{ mx: 2, color: '#555555' }}>OR</Box>
          <Box sx={{ flex: 1, height: '1px', backgroundColor: '#555555' }} />
        </Box>
        <Box sx={{ height: 10 }} />
        <CustomButtonExternalLoginProvider text="Continue with Google" onClick={handleGoogleLogin} icon={<GoogleIcon />} />
        <Box sx={{ height: 20 }} />
        <CustomButtonExternalLoginProvider text="Continue with GitHub" onClick={handleGithubLogin} icon={<GitHubIcon />} />
        <Box sx={{ height: 40 }} />
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <p>
            Don't have account?{' '}
            <Link to="/signup" style={{ color: '#006666', textDecoration: 'none' }}>
              Create One
            </Link>
          </p>
        </Box>
        <Box sx={{ height: 60 }} />
      </Box>
    </PaddedCentralBox>
  );
};

export default Login;