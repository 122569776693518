import React from 'react';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const COLORS = ['#999999', '#006666'];

const CustomLegend = ({ payload }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: 2 }}>
      {payload.map((entry, index) => (
        <Box key={`item-${index}`} sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
          <Box sx={{ backgroundColor: entry.color, width: 10, height: 10, marginRight: 1 }} />
          <Typography variant="body2" sx={{ color: '#555555' }}>
            {entry.value}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

const CompletionPieChart = ({ completed, total, showLegend = true }) => {
  const data = [
    { name: 'Remaining', value: total - completed },
    { name: 'Completed', value: completed },
  ];

  return (
    <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
      <PieChart width={200} height={250}>
        <Pie
          data={data}
          cx={100}
          cy={100}
          innerRadius={40}
          outerRadius={80}
          fill="#8884d8"
          paddingAngle={5}
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
      {showLegend && (
        <CustomLegend payload={data.map((entry, index) => ({ color: COLORS[index % COLORS.length], value: entry.name }))} />
      )}
    </Box>
  );
};

export default CompletionPieChart;