import React from 'react';
import Box from '@mui/material/Box';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

function LinuxCommand({ command }) {
    const handleClick = () => {
        navigator.clipboard.writeText(command);
    };

    return (
        <Box 
            sx={{ 
                p: 2, 
                color: '#FFFFFF', 
                bgcolor: '#555555', 
                fontFamily: 'Monospace', 
                borderRadius: '8px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}
        >
            <Box sx={{ 
                flexGrow: 1,
                color: '#FFFFFF', 
                fontFamily: 'Roboto Mono, monospace',
                fontSize: '13px', }}>
                {command}
            </Box>
            <ContentCopyIcon 
                onClick={handleClick}
                sx={{ 
                    color: '#FFFFFF', 
                    cursor: 'pointer',
                    fontSize: '20px', // Adjust the font size here
                    '&:hover': {
                        color: '#AAAAAA'
                    }
                }} 
            />
        </Box>
    );
}

export default LinuxCommand;