import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { useStripe, Elements } from '@stripe/react-stripe-js';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getAuth } from 'firebase/auth';
import { loadStripe } from '@stripe/stripe-js';
import { useMediaQuery } from '@mui/material';

// Initialize Stripe with your publishable key
const stripePromise = loadStripe('pk_test_51Q7QnaFmeN4XikuSDFAYHRnpZcGlrZ7yIyE5GbZ51KJygKU3UQU94OeLmZhrl2nEJT3UAeiwopFJgkXvDp8hnP1s00XI9gKeed');

const CustomGetPremiumButton = ({ text, sx, setLoading }) => {
    const stripe = useStripe();
    const functions = getFunctions();
    const auth = getAuth();

    const handleClick = async () => {
        setLoading(true);
        const user = auth.currentUser;
        if (!user) {
            console.error('User not authenticated');
            setLoading(false);
            return;
        }

        const createStripePayment = httpsCallable(functions, 'createStripePayment');
        try {
            const { data } = await createStripePayment({});
            const { sessionId } = data; // Extract both sessionId and clientSecret

            // Use sessionId for redirectToCheckout
            const result = await stripe.redirectToCheckout({ sessionId });

            if (result.error) {
                console.error(result.error.message);
            }

            // Optionally, use clientSecret for other Stripe operations
            // Example: Confirm a payment intent
            // const paymentResult = await stripe.confirmCardPayment(clientSecret);
            // if (paymentResult.error) {
            //     console.error(paymentResult.error.message);
            // }

        } catch (error) {
            console.error('Error creating payment:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <button 
            onClick={handleClick} 
            className="custom-button-get-premium"
            style={sx}
        >
            {text}
        </button>
    );
};

const Banner = ({ title, route }) => {
    const [loading, setLoading] = useState(false);
    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <Elements stripe={stripePromise}>
            <Box
                sx={{
                    backgroundColor: '#BCD6D6',
                    borderRadius: 2,
                    paddingY: 2,
                    paddingX: 2,
                    paddingBottom: 2,
                    position: 'relative', // Ensure the Box is positioned relatively
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    justifyContent: 'space-between',
                    minHeight: 80
                }}
            >
                <Box sx={{ paddingRight: 10, maxWidth: 600 }}>
                    <Typography variant="h2" sx={{ fontSize: 28, color: '#006666', fontWeight: 'bold' }}>
                        {title}
                    </Typography>
                </Box>
                { isMobile && <Box sx={{ height: 20 }} /> }
                <Box>
                    {loading ? (
                        <CircularProgress sx={{ color: '#006666' }} />
                    ) : (
                        <CustomGetPremiumButton text="Get Premium" sx={{}} setLoading={setLoading} />
                    )}
                </Box>
            </Box>
            <Box sx={{ height: 30 }} />
        </Elements>
    );
};

Banner.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
};

export default Banner;