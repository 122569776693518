import React from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

const StyledTextField = ({ id, type = "text", onChange, value, helperText }) => (
    <Box sx={{ minHeight: '75px' }}>
        <TextField
            id={id}
            type={type}
            variant="outlined"
            fullWidth
            onChange={onChange}
            value={value}
            helperText={helperText}
            sx={{
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderColor: '#006666',
                    },
                    '&:hover fieldset': {
                        borderColor: '#006666',
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: '#006666',
                    },
                },
                '& .MuiOutlinedInput-input': {
                    paddingTop: '10px',
                    paddingBottom: '10px',
                },
                '& .MuiFormHelperText-root': {
                    color: '#a2423d',
                },
            }}
        />
    </Box>
);

export default StyledTextField;