import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import RouteTitle from '../components/RouteTitle';
import ConfigurableField from '../components/ConfigurableField';
import { CustomButtonWide } from '../components/CustomButton';
import { Link, useHistory } from "react-router-dom";
import PaddedCentralBox from '../components/PaddedCentralBox';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth, db } from '../firebaseConfig';
import { doc, setDoc } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid'; // Import uuid library

const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailHelperText, setEmailHelperText] = useState('');
  const [passwordHelperText, setPasswordHelperText] = useState('');
  const [repeatPasswordHelperText, setRepeatPasswordHelperText] = useState('');
  const [firstNameHelperText, setFirstNameHelperText] = useState('');
  const [lastNameHelperText, setLastNameHelperText] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState(''); // State for error message
  const history = useHistory();

  useEffect(() => {
    const isValid = 
      validateEmail(email) &&
      password.length >= 6 &&
      password === repeatPassword &&
      firstName.length >= 2 &&
      lastName.length >= 2;
    setIsFormValid(isValid);
  }, [email, password, repeatPassword, firstName, lastName]);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const getFriendlyErrorMessage = (errorCode) => {
    switch (errorCode) {
      case 'auth/email-already-in-use':
        return 'This email is already in use. Please use a different email.';
      case 'auth/invalid-email':
        return 'The email address is not valid. Please enter a valid email.';
      case 'auth/operation-not-allowed':
        return 'Email/password accounts are not enabled. Please contact support.';
      case 'auth/weak-password':
        return 'The password is too weak. Please enter a stronger password.';
      default:
        return 'An unexpected error occurred. Please try again later.';
    }
  };

  const handleSignup = async () => {
    setErrorMessage(''); // Clear previous error message
    if (!validateEmail(email)) {
      setEmailHelperText('Invalid email format');
      return;
    }
    if (password.length < 6) {
      setPasswordHelperText('Password must be at least 6 characters');
      return;
    }
    if (password !== repeatPassword) {
      setRepeatPasswordHelperText('Passwords do not match');
      return;
    }
    if (firstName.length < 2) {
      setFirstNameHelperText('First name must be at least 2 characters');
      return;
    }
    if (lastName.length < 2) {
      setLastNameHelperText('Last name must be at least 2 characters');
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Generate a UUID
      const uuid = uuidv4();

      // Save user details in Firestore
      await setDoc(doc(db, "users", user.uid), {
        firstName,
        lastName,
        email,
        uuid // Add UUID to user details
      });

      history.push('/'); // Redirect to home page after successful signup
    } catch (error) {
      const friendlyMessage = getFriendlyErrorMessage(error.code);
      setErrorMessage(friendlyMessage); // Set friendly error message
      console.error("Error signing up: ", error);
    }
  };

  return (
    <PaddedCentralBox>
      <Box sx={{ width: 325 }}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <RouteTitle title="Sign Up" />
        </Box>
        <Box sx={{ height: 40 }} />
        <ConfigurableField 
          label="First Name:" 
          textFieldId="first-name" 
          onChange={(e) => {
            setFirstName(e.target.value);
            setFirstNameHelperText(e.target.value.length >= 2 ? '' : 'First name must be at least 2 characters');
          }} 
          helperText={firstNameHelperText} 
        />
        <ConfigurableField 
          label="Last Name:" 
          textFieldId="last-name" 
          onChange={(e) => {
            setLastName(e.target.value);
            setLastNameHelperText(e.target.value.length >= 2 ? '' : 'Last name must be at least 2 characters');
          }} 
          helperText={lastNameHelperText} 
        />
        <ConfigurableField 
          label="Email:" 
          textFieldId="email" 
          onChange={(e) => {
            setEmail(e.target.value);
            setEmailHelperText(validateEmail(e.target.value) ? '' : 'Invalid email format');
          }} 
          helperText={emailHelperText} 
        />
        <ConfigurableField 
          label="Password:" 
          textFieldId="password" 
          type="password" 
          onChange={(e) => {
            setPassword(e.target.value);
            setPasswordHelperText(e.target.value.length >= 6 ? '' : 'Password must be at least 6 characters');
            setRepeatPasswordHelperText(e.target.value === repeatPassword ? '' : 'Passwords do not match');
          }} 
          helperText={passwordHelperText} 
        />
        <ConfigurableField 
          label="Repeat Password:" 
          textFieldId="repeat-password" 
          type="password" 
          onChange={(e) => {
            setRepeatPassword(e.target.value);
            setRepeatPasswordHelperText(e.target.value === password ? '' : 'Passwords do not match');
          }} 
          helperText={repeatPasswordHelperText} 
        />
        <Box sx={{ height: 40 }} />
        {errorMessage && (
          <Box sx={{ color: '#a2423d', marginBottom: 2 }}>
            {errorMessage}
          </Box>
        )}
        <CustomButtonWide text="Sign Up" onClick={handleSignup} disabled={!isFormValid} />
        <Box sx={{ height: 40 }} />
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <p>
            Already have account?{' '}
            <Link to="/login" style={{ color: '#006666', textDecoration: 'none' }}>
              Login
            </Link>
          </p>
        </Box>
        <Box sx={{ height: 40 }} />
      </Box>
    </PaddedCentralBox>
  );
};

export default Signup;