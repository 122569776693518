import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

const Material = ({ title, content, link, disabled }) => {
  return (
    <Box sx={{ padding: 0, marginBottom: 4 }}>
      {disabled ? (
        <Typography sx={{ fontSize: 16, color: '#c4c4c4', textDecoration: 'underline', fontWeight: 'bold' }}>
          {title}
        </Typography>
      ) : (
        <Link to={link} style={{ textDecoration: 'none' }}>
          <Typography sx={{ fontSize: 16, color: '#006666', textDecoration: 'underline', fontWeight: 'bold' }}>
            {title}
          </Typography>
        </Link>
      )}
      <Box sx={{ height: 6 }} />
      <Typography sx={{ fontSize: 14, color: '#555555' }}>
        {content}
      </Typography>
    </Box>
  );
};

export default Material;