import React from 'react';
import Box from '@mui/material/Box';
import RouteTitle from './RouteTitle'; // Adjust the import path as necessary
import Banner from './Banner'; // Import the Banner component
import { useMediaQuery } from '@mui/material';

const PaddedBox = ({ children, title, showBanner = true }) => {
    const isMobile = useMediaQuery('(max-width:950px)');

    return (
        <Box sx={{ 
            paddingLeft: isMobile ? 2 : 20, 
            paddingRight: isMobile ? 2 : 20, 
            paddingTop: 5, 
            paddingBottom: 10,
            maxWidth: '1400px',
            margin: '0 auto'
        }}>
            {title && (
                <>
                    {showBanner && (
                        <Banner title="Don’t miss out! Unlock all features and lessons for just $49!" route="/some-route" />
                    )}
                    <RouteTitle title={title}/>
                    <Box sx={{ height: 30 }} />
                </>
            )}
            {children}
        </Box>
    );
};

export default PaddedBox;