import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import CourseComponent from '../components/CourseComponent';
import { useParams } from 'react-router-dom';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { parseLessons } from '../components/LessonsParser';
import { useMediaQuery } from '@mui/material';

const Lesson = () => {
    const { id } = useParams();
    const [lessons, setLessons] = useState([]);
    const [lessonName, setLessonName] = useState(null);
    const [lessonSummary, setLessonSummary] = useState(null);
    const [script, setScript] = useState(null);
    const [loading, setLoading] = useState(true);
    const isMobile = useMediaQuery('(max-width:1200px)');

    useEffect(() => {
        const fetchLesson = async () => {
            const db = getFirestore();
            const docRef = doc(db, 'lessons', id);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const data = docSnap.data();
                const parsedLessons = parseLessons(JSON.parse(data.content));
                setLessons(parsedLessons);
                setScript(data.script);
                setLessonName(data.lesson_name);
                setLessonSummary(data.lesson_summary);
            } else {
                console.log('No such document!');
            }
            setLoading(false);
        };

        fetchLesson();
    }, [id]);

    if (loading) {
        return <Box sx={{ paddingLeft: isMobile ? 2 : 20, paddingRight: isMobile ? 2 : 20, paddingTop: 3 }}>Loading...</Box>;
    }

    return (
        <Box 
            sx={{ 
                paddingLeft: isMobile ? 2 : 10,
                paddingRight: isMobile ? 2 : 10,
                paddingTop: 3,
                paddingBottom: isMobile ? 2 : 10,
                maxWidth: '1400px',
                margin: '0 auto'
            }}
        >
            <CourseComponent 
                lessons={lessons} 
                script={script} 
                lessonName={lessonName} 
                lessonSummary={lessonSummary}
            />
        </Box>
    );
};

export default Lesson;