// src/components/ConfigurableField.js
import React from 'react';
import { Box } from '@mui/material';
import StyledTextField from './StyledTextField'; // Adjust the import based on your project structure

const ConfigurableField = ({ label, textFieldId, type = "text", onChange, value, helperText }) => {
  return (
    <div>
      <p>{label}</p>
      <StyledTextField 
        id={textFieldId} 
        type={type} 
        onChange={onChange} 
        value={value} 
        helperText={helperText} 
      />
    </div>
  );
};

export default ConfigurableField;